<template>
  <senso-layout>
    <p>
      Please select a tab above.
    </p>
  </senso-layout>
</template>

<script>
import SensoLayout from "./SensoLayout.vue";
export default {
  components: {SensoLayout}
}
</script>

<style>

</style>